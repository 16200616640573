import { all } from 'redux-saga/effects';

import { AuthenticateSagas } from './AuthenticateSagas';
import { UserSagas } from './UserSagas';
import { RoleSagas } from "./RoleSagas";
import { InstanceSagas } from "./InstanceSagas";
import { SettingSagas } from "./SettingSagas";
import { TaskMonitorSagas } from "./TaskMonitorSagas";
import { SystemStatusSagas } from "./SystemStatusSagas";
import { ConnectorSagas } from './ConnectorSagas';
import { ConnectorContainerSagas } from './ConnectorContainerSagas'
import { ImageSagas } from './ImageSagas';
import { ImageVersionSagas } from './ImageVersionSagas';
import { HistorySagas } from './HistorySagas';
import { DashboardSagas } from './DashboardSagas';
import { DatabaseTransferSagas } from './DatabaseTransferSagas';
import { ClientSagas } from './ClientSagas';
import { PropertySagas } from './PropertySagas';
import { FormSagas } from './FormSagas';
import { TrialSagas } from './TrialSagas';
import { ServiceSagas } from './ServiceSagas';
import { MailSagas } from './MailSagas';
import { MailLogSagas } from './MailLogSagas';
import { WizardSagas } from './WizardSagas';
import { StepviewSagas } from './StepviewSagas';
import { WidgetSagas } from './WidgetSagas';
import { ConditionSagas } from './ConditionSagas';
import { BranchSagas } from './BranchSagas';
import { StepviewWidgetSagas } from './StepviewWidgetSagas';
import { ServiceContainerSagas } from './ServiceContainerSagas';
import { PackageSagas } from './PackageSagas';
import { CentralInstanceSagas } from './CentralInstanceSagas';
import { ServiceMigrationSagas } from './ServiceMigrationSagas';
import { ServiceVersionSagas } from './ServiceVersionSagas';
import { MetabaseSagas } from './MetabaseSagas';

export function* RootSagas () {
   yield all([
      AuthenticateSagas(),
      UserSagas(),
      RoleSagas(),
      InstanceSagas(),
      SettingSagas(),
      TaskMonitorSagas(),
      SystemStatusSagas(),
      ConnectorSagas(),
      ConnectorContainerSagas(),
      ImageSagas(),
      ImageVersionSagas(),
      HistorySagas(),
      DashboardSagas(),
      DatabaseTransferSagas(),
      ClientSagas(),
      PropertySagas(),
      FormSagas(),
      TrialSagas(),
      ServiceSagas(),
      ServiceContainerSagas(),
      MailSagas(),
      MailLogSagas(),
      WizardSagas(),
      StepviewSagas(),
      WidgetSagas(),
      ConditionSagas(),
      BranchSagas(),
      StepviewWidgetSagas(),
      PackageSagas(),
      CentralInstanceSagas(),
      ServiceMigrationSagas(),
      ServiceVersionSagas(),
      MetabaseSagas()
    ])
 }

