/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface MetabaseDashboardModelInterface {
    id?: number;
    name?: string;
    description?: string;
    archived?: boolean;
    view_count?: number;
    enable_embedding?: boolean;
    collection_id?: number;
    creator_id?: number;
    position?: number;
    collection_name?: string;
    created_at?: string;
    updated_at?: string;
}

/**
 * Metabase Dashboard Model
 * @export
 * @interface MetabaseDashboardModel
 */
export class MetabaseDashboardModel extends BaseModel<MetabaseDashboardModelInterface> {

    /**
     * The ID of the dashboard
     * @type {number}
     * @memberof MetabaseDashboardModel
     */
    id?: number;
    /**
     * The name of the dashboard
     * @type {string}
     * @memberof MetabaseDashboardModel
     */
    name?: string;
    /**
     * Dashboard description
     * @type {string}
     * @memberof MetabaseDashboardModel
     */
    description?: string;
    /**
     * Whether the dashboard is archived
     * @type {boolean}
     * @memberof MetabaseDashboardModel
     */
    archived?: boolean;
    /**
     * The view count
     * @type {number}
     * @memberof MetabaseDashboardModel
     */
    view_count?: number;
    /**
     * Whether the embedding is enabled for the dashboard
     * @type {boolean}
     * @memberof MetabaseDashboardModel
     */
    enable_embedding?: boolean;
    /**
     * The ID of the collection
     * @type {number}
     * @memberof MetabaseDashboardModel
     */
    collection_id?: number;
    /**
     * The creator id
     * @type {number}
     * @memberof MetabaseDashboardModel
     */
    creator_id?: number;
    /**
     * The position
     * @type {number}
     * @memberof MetabaseDashboardModel
     */
    position?: number;
    /**
     * The name of the collection
     * @type {string}
     * @memberof MetabaseDashboardModel
     */
    collection_name?: string;
    /**
     * Created timestamp
     * @type {string}
     * @memberof MetabaseDashboardModel
     */
    created_at?: string;
    /**
     * Updated timestamp
     * @type {string}
     * @memberof MetabaseDashboardModel
     */
    updated_at?: string;

    constructor(data?: MetabaseDashboardModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: MetabaseDashboardModel, value: StringDateInterface<MetabaseDashboardModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function MetabaseDashboardModelFromJSON(json: any): MetabaseDashboardModel {
    return MetabaseDashboardModelFromJSONTyped(json, false);
}

export function MetabaseDashboardModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetabaseDashboardModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MetabaseDashboardModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseDashboardModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'archived': !exists(json, 'archived') ? undefined : json['archived'],
        'view_count': !exists(json, 'view_count') ? undefined : Number(json['view_count']),
        'enable_embedding': !exists(json, 'enable_embedding') ? undefined : json['enable_embedding'],
        'collection_id': !exists(json, 'collection_id') ? undefined : Number(json['collection_id']),
        'creator_id': !exists(json, 'creator_id') ? undefined : Number(json['creator_id']),
        'position': !exists(json, 'position') ? undefined : Number(json['position']),
        'collection_name': !exists(json, 'collection_name') ? undefined : json['collection_name'],
        'created_at': !exists(json, 'created_at') ? undefined : json['created_at'],
        'updated_at': !exists(json, 'updated_at') ? undefined : json['updated_at'],
    });
}

export function MetabaseDashboardModelToJSON(value?: MetabaseDashboardModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MetabaseDashboardModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseDashboardModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'archived': value.archived,
        'view_count': value.view_count,
        'enable_embedding': value.enable_embedding,
        'collection_id': value.collection_id,
        'creator_id': value.creator_id,
        'position': value.position,
        'collection_name': value.collection_name,
        'created_at': value.created_at,
        'updated_at': value.updated_at,
    } as StringDateInterface<MetabaseDashboardModel>;
    return createClass.toJson(value, model);
}


