/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';
import {
    MetabaseDashboardModel,
    MetabaseDashboardModelFromJSON,
    MetabaseDashboardModelFromJSONTyped,
    MetabaseDashboardModelToJSON,
} from './';


export interface MetabaseDashboardsListResultModelInterface {
    count_unfiltered?: number;
    count_filtered?: number;
    items?: Array<MetabaseDashboardModel>;
}

/**
 * 
 * @export
 * @interface MetabaseDashboardsListResultModel
 */
export class MetabaseDashboardsListResultModel extends BaseModel<MetabaseDashboardsListResultModelInterface> {

    /**
     * Total count of items without filter
     * @type {number}
     * @memberof MetabaseDashboardsListResultModel
     */
    count_unfiltered?: number;
    /**
     * Count of items with filter
     * @type {number}
     * @memberof MetabaseDashboardsListResultModel
     */
    count_filtered?: number;
    /**
     * 
     * @type {Array<MetabaseDashboardModel>}
     * @memberof MetabaseDashboardsListResultModel
     */
    items?: Array<MetabaseDashboardModel>;

    constructor(data?: MetabaseDashboardsListResultModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: MetabaseDashboardsListResultModel, value: StringDateInterface<MetabaseDashboardsListResultModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function MetabaseDashboardsListResultModelFromJSON(json: any): MetabaseDashboardsListResultModel {
    return MetabaseDashboardsListResultModelFromJSONTyped(json, false);
}

export function MetabaseDashboardsListResultModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetabaseDashboardsListResultModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MetabaseDashboardsListResultModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseDashboardsListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'count_unfiltered': !exists(json, 'count_unfiltered') ? undefined : Number(json['count_unfiltered']),
        'count_filtered': !exists(json, 'count_filtered') ? undefined : Number(json['count_filtered']),
        'items': !exists(json, 'items') ? undefined : ((json['items'] as Array<any>).map(MetabaseDashboardModelFromJSON)),
    });
}

export function MetabaseDashboardsListResultModelToJSON(value?: MetabaseDashboardsListResultModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MetabaseDashboardsListResultModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseDashboardsListResultModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'count_unfiltered': value.count_unfiltered,
        'count_filtered': value.count_filtered,
        'items': value.items === undefined ? undefined : ((value.items as Array<any>).map(MetabaseDashboardModelToJSON)),
    } as StringDateInterface<MetabaseDashboardsListResultModel>;
    return createClass.toJson(value, model);
}


