/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiErrorResponseModel,
    ApiErrorResponseModelFromJSON,
    ApiErrorResponseModelToJSON,
    HistoryModelRetrieveListModel,
    HistoryModelRetrieveListModelFromJSON,
    HistoryModelRetrieveListModelToJSON,
    HistoryResponseModel,
    HistoryResponseModelFromJSON,
    HistoryResponseModelToJSON,
    MetabaseApiKeyModel,
    MetabaseApiKeyModelFromJSON,
    MetabaseApiKeyModelToJSON,
    MetabaseCreateModel,
    MetabaseCreateModelFromJSON,
    MetabaseCreateModelToJSON,
    MetabaseCreateSuccessModel,
    MetabaseCreateSuccessModelFromJSON,
    MetabaseCreateSuccessModelToJSON,
    MetabaseDashboardsListResultModel,
    MetabaseDashboardsListResultModelFromJSON,
    MetabaseDashboardsListResultModelToJSON,
    MetabaseListResultModel,
    MetabaseListResultModelFromJSON,
    MetabaseListResultModelToJSON,
    MetabaseModel,
    MetabaseModelFromJSON,
    MetabaseModelToJSON,
    MetabasePatchSuccessModel,
    MetabasePatchSuccessModelFromJSON,
    MetabasePatchSuccessModelToJSON,
    MetabaseRemoveSuccessModel,
    MetabaseRemoveSuccessModelFromJSON,
    MetabaseRemoveSuccessModelToJSON,
    MetabaseRetrieveDashboardsListModel,
    MetabaseRetrieveDashboardsListModelFromJSON,
    MetabaseRetrieveDashboardsListModelToJSON,
    MetabaseRetrieveListModel,
    MetabaseRetrieveListModelFromJSON,
    MetabaseRetrieveListModelToJSON,
    MetabaseToggleSuccessModel,
    MetabaseToggleSuccessModelFromJSON,
    MetabaseToggleSuccessModelToJSON,
} from '../models';

export interface MetabaseCreateRequest {
    metabaseCreateModel: MetabaseCreateModel;
}

export interface MetabaseDashboardToggleRequest {
    id: number;
    disabled?: MetabaseDashboardToggleDisabledEnum;
}

export interface MetabaseDeleteRequest {
    id: number;
}

export interface MetabaseHistoryListRequest {
    id: string;
    historyModelRetrieveListModel: HistoryModelRetrieveListModel;
}

export interface MetabaseListRequest {
    metabaseRetrieveListModel: MetabaseRetrieveListModel;
}

export interface MetabaseRefreshTokenRequest {
    uuid: string;
}

export interface MetabaseRetrieveRequest {
    id: number;
}

export interface MetabaseRetrieveApiKeyRequest {
    uuid: string;
}

export interface MetabaseRetrieveDashboardsRequest {
    metabaseRetrieveDashboardsListModel: MetabaseRetrieveDashboardsListModel;
    disabled?: MetabaseRetrieveDashboardsDisabledEnum;
}

/**
 * 
 */
export class MetabaseApi extends runtime.BaseAPI {

    /**
     * Create a new metabase account
     * Create a new metabase account
     */
    async metabaseCreateRaw(requestParameters: MetabaseCreateRequest): Promise<runtime.ApiResponse<MetabaseCreateSuccessModel>> {
        if (requestParameters.metabaseCreateModel === null || requestParameters.metabaseCreateModel === undefined) {
            throw new runtime.RequiredError('metabaseCreateModel','Required parameter requestParameters.metabaseCreateModel was null or undefined when calling metabaseCreate.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/metabase`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetabaseCreateModelToJSON(requestParameters.metabaseCreateModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetabaseCreateSuccessModelFromJSON(jsonValue));
    }

    /**
     * Create a new metabase account
     * Create a new metabase account
     */
    metabaseCreate = async(requestParameters: MetabaseCreateRequest): Promise<MetabaseCreateSuccessModel> => {
        const response = await this.metabaseCreateRaw(requestParameters);
        return await response.value();
    }

    /**
     * Toggle the archived status of a dashboard
     * Toggle the archived status of a dashboard
     */
    async metabaseDashboardToggleRaw(requestParameters: MetabaseDashboardToggleRequest): Promise<runtime.ApiResponse<MetabaseToggleSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling metabaseDashboardToggle.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.disabled !== undefined) {
            queryParameters['disabled'] = requestParameters.disabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/metabase/dashboards/toggle/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetabaseToggleSuccessModelFromJSON(jsonValue));
    }

    /**
     * Toggle the archived status of a dashboard
     * Toggle the archived status of a dashboard
     */
    metabaseDashboardToggle = async(requestParameters: MetabaseDashboardToggleRequest): Promise<MetabaseToggleSuccessModel> => {
        const response = await this.metabaseDashboardToggleRaw(requestParameters);
        return await response.value();
    }

    /**
     * Delete a metabase account
     * Delete a metabase account
     */
    async metabaseDeleteRaw(requestParameters: MetabaseDeleteRequest): Promise<runtime.ApiResponse<MetabaseRemoveSuccessModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling metabaseDelete.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/metabase/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetabaseRemoveSuccessModelFromJSON(jsonValue));
    }

    /**
     * Delete a metabase account
     * Delete a metabase account
     */
    metabaseDelete = async(requestParameters: MetabaseDeleteRequest): Promise<MetabaseRemoveSuccessModel> => {
        const response = await this.metabaseDeleteRaw(requestParameters);
        return await response.value();
    }

    /**
     * retrieve a list of historical logs for an metabase
     * retrieve a list of historical logs for an metabase
     */
    async metabaseHistoryListRaw(requestParameters: MetabaseHistoryListRequest): Promise<runtime.ApiResponse<HistoryResponseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling metabaseHistoryList.');
        }

        if (requestParameters.historyModelRetrieveListModel === null || requestParameters.historyModelRetrieveListModel === undefined) {
            throw new runtime.RequiredError('historyModelRetrieveListModel','Required parameter requestParameters.historyModelRetrieveListModel was null or undefined when calling metabaseHistoryList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/metabase/{id}/history`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: HistoryModelRetrieveListModelToJSON(requestParameters.historyModelRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryResponseModelFromJSON(jsonValue));
    }

    /**
     * retrieve a list of historical logs for an metabase
     * retrieve a list of historical logs for an metabase
     */
    metabaseHistoryList = async(requestParameters: MetabaseHistoryListRequest): Promise<HistoryResponseModel> => {
        const response = await this.metabaseHistoryListRaw(requestParameters);
        return await response.value();
    }

    /**
     * List all metabases accounts
     * List all metabase accounts
     */
    async metabaseListRaw(requestParameters: MetabaseListRequest): Promise<runtime.ApiResponse<MetabaseListResultModel>> {
        if (requestParameters.metabaseRetrieveListModel === null || requestParameters.metabaseRetrieveListModel === undefined) {
            throw new runtime.RequiredError('metabaseRetrieveListModel','Required parameter requestParameters.metabaseRetrieveListModel was null or undefined when calling metabaseList.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/metabase/accounts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetabaseRetrieveListModelToJSON(requestParameters.metabaseRetrieveListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetabaseListResultModelFromJSON(jsonValue));
    }

    /**
     * List all metabases accounts
     * List all metabase accounts
     */
    metabaseList = async(requestParameters: MetabaseListRequest): Promise<MetabaseListResultModel> => {
        const response = await this.metabaseListRaw(requestParameters);
        return await response.value();
    }

    /**
     * Refresh a metabase api key
     * Refresh a metabase api key
     */
    async metabaseRefreshTokenRaw(requestParameters: MetabaseRefreshTokenRequest): Promise<runtime.ApiResponse<MetabasePatchSuccessModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling metabaseRefreshToken.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/metabase/apikey/refresh-token/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetabasePatchSuccessModelFromJSON(jsonValue));
    }

    /**
     * Refresh a metabase api key
     * Refresh a metabase api key
     */
    metabaseRefreshToken = async(requestParameters: MetabaseRefreshTokenRequest): Promise<MetabasePatchSuccessModel> => {
        const response = await this.metabaseRefreshTokenRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a metabase account by its id
     * Retrieve a metabase account by its id
     */
    async metabaseRetrieveRaw(requestParameters: MetabaseRetrieveRequest): Promise<runtime.ApiResponse<MetabaseModel>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling metabaseRetrieve.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/metabase/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetabaseModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a metabase account by its id
     * Retrieve a metabase account by its id
     */
    metabaseRetrieve = async(requestParameters: MetabaseRetrieveRequest): Promise<MetabaseModel> => {
        const response = await this.metabaseRetrieveRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a metabase api key by the instance UUID
     * Retrieve a metabase api key by the instance UUID
     */
    async metabaseRetrieveApiKeyRaw(requestParameters: MetabaseRetrieveApiKeyRequest): Promise<runtime.ApiResponse<MetabaseApiKeyModel>> {
        if (requestParameters.uuid === null || requestParameters.uuid === undefined) {
            throw new runtime.RequiredError('uuid','Required parameter requestParameters.uuid was null or undefined when calling metabaseRetrieveApiKey.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/metabase/apikey/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(requestParameters.uuid))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetabaseApiKeyModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a metabase api key by the instance UUID
     * Retrieve a metabase api key by the instance UUID
     */
    metabaseRetrieveApiKey = async(requestParameters: MetabaseRetrieveApiKeyRequest): Promise<MetabaseApiKeyModel> => {
        const response = await this.metabaseRetrieveApiKeyRaw(requestParameters);
        return await response.value();
    }

    /**
     * Retrieve a list of dashboards
     * Retrieve a list of dashboards
     */
    async metabaseRetrieveDashboardsRaw(requestParameters: MetabaseRetrieveDashboardsRequest): Promise<runtime.ApiResponse<MetabaseDashboardsListResultModel>> {
        if (requestParameters.metabaseRetrieveDashboardsListModel === null || requestParameters.metabaseRetrieveDashboardsListModel === undefined) {
            throw new runtime.RequiredError('metabaseRetrieveDashboardsListModel','Required parameter requestParameters.metabaseRetrieveDashboardsListModel was null or undefined when calling metabaseRetrieveDashboards.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.disabled !== undefined) {
            queryParameters['disabled'] = requestParameters.disabled;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        const response = await this.request({
            path: `/metabase/dashboards`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: MetabaseRetrieveDashboardsListModelToJSON(requestParameters.metabaseRetrieveDashboardsListModel),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => MetabaseDashboardsListResultModelFromJSON(jsonValue));
    }

    /**
     * Retrieve a list of dashboards
     * Retrieve a list of dashboards
     */
    metabaseRetrieveDashboards = async(requestParameters: MetabaseRetrieveDashboardsRequest): Promise<MetabaseDashboardsListResultModel> => {
        const response = await this.metabaseRetrieveDashboardsRaw(requestParameters);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum MetabaseDashboardToggleDisabledEnum {
    True = 'true',
    False = 'false'
}
/**
    * @export
    * @enum {string}
    */
export enum MetabaseRetrieveDashboardsDisabledEnum {
    True = 'true',
    False = 'false'
}
