/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface MetabaseCreateModelInterface {
    uuid?: string;
}

/**
 * 
 * @export
 * @interface MetabaseCreateModel
 */
export class MetabaseCreateModel extends BaseModel<MetabaseCreateModelInterface> {

    /**
     * The UUID of the resource
     * @type {string}
     * @memberof MetabaseCreateModel
     */
    uuid?: string;

    constructor(data?: MetabaseCreateModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: MetabaseCreateModel, value: StringDateInterface<MetabaseCreateModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function MetabaseCreateModelFromJSON(json: any): MetabaseCreateModel {
    return MetabaseCreateModelFromJSONTyped(json, false);
}

export function MetabaseCreateModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetabaseCreateModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MetabaseCreateModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseCreateModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
    });
}

export function MetabaseCreateModelToJSON(value?: MetabaseCreateModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MetabaseCreateModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseCreateModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'uuid': value.uuid,
    } as StringDateInterface<MetabaseCreateModel>;
    return createClass.toJson(value, model);
}


