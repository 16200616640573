import { createStore, combineReducers, applyMiddleware } from "redux";
import { createReduxHistoryContext } from "redux-first-history";
import { createBrowserHistory } from 'history';
import createSagaMiddleware from "redux-saga";
import StateChangeMiddleware from 'services/StateChangeMiddleware';
import { ApiReducers } from '../reducers/ApiReducers';
import { AppReducers } from '../reducers/AppReducers';
import { UserReducers } from '../reducers/UserReducers';
import { SettingReducers } from '../reducers/SettingReducers';
import { InstanceReducers } from '../reducers/InstanceReducers';
import { RoleReducers } from "../reducers/RoleReducers";
import { TaskMonitorReducers } from "../reducers/TaskMonitorReducers";
import { SystemStatusReducers } from "../reducers/SystemStatusReducers";
import { ConnectorReducers } from "../reducers/ConnectorReducers";
import { RootSagas } from '../sagas/RootSagas';
import { ConnectorContainerReducers } from "reducers/ConnectorContainerReducers";
import { ImageReducers } from "reducers/ImageReducers";
import { ImageVersionReducers } from "reducers/ImageVersionReducers";
import { FilterReducers } from "reducers/FilterReducers";
import { HistoryReducers } from "reducers/HistoryReducers";
import { DashboardReducers } from "reducers/DashboardReducers";
import * as Sentry from "@sentry/react";
import ActionTypes from "config/ActionTypes";
import { ClientReducers } from "reducers/ClientReducers";
import { PropertyReducers } from "reducers/PropertyReducers";
import { FormReducers } from "reducers/FormReducers";
import { TrialReducers } from "reducers/TrialReducers";
import { ServiceReducers } from "reducers/ServiceReducers";
import { MailReducers } from "reducers/MailReducers";
import { MailLogReducers } from "reducers/MailLogReducers";
import { ServiceContainerReducers } from "reducers/ServiceContainerReducers";
import { WizardReducers } from "reducers/WizardReducers";
import { StepviewReducers } from "reducers/StepviewReducers";
import { WidgetReducers } from "reducers/WidgetReducers";
import { ConditionReducers } from "reducers/ConditionReducers";
import { BranchReducers } from "reducers/BranchReducers";
import { StepviewWidgetReducers } from "reducers/StepviewWidgetReducers";
import { composeWithDevTools } from "redux-devtools-extension";
import { PackageReducers } from "reducers/PackageReducers";
import { CentralInstanceReducers } from "reducers/CentralInstanceReducers";
import { ServiceMigrationReducers } from "reducers/ServiceMigrationReducers";
import { MetabaseReducers } from "reducers/MetabaseReducers";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ 
    history: createBrowserHistory()
});
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = composeWithDevTools({});
const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action) => {
        if (action.type === ActionTypes.API_SIGN_IN) {
            return {
                ...action,
                params: {
                    ...action.params,
                    password: null,
                }
            }
        }
        return action;
    }
});
export const store = createStore(
    combineReducers({
        router: routerReducer,
        api: ApiReducers,
        users: UserReducers,
        settings: SettingReducers,
        app: AppReducers,
        roles: RoleReducers,
        instances: InstanceReducers,
        task_monitor: TaskMonitorReducers,
        system_status: SystemStatusReducers,
        connectors: ConnectorReducers,
        connectorContainers: ConnectorContainerReducers,
        images: ImageReducers,
        image_versions: ImageVersionReducers,
        filter: FilterReducers,
        history: HistoryReducers,
        dashboard: DashboardReducers,
        clients: ClientReducers,
        properties: PropertyReducers,
        forms: FormReducers,
        trials: TrialReducers,
        wizards: WizardReducers,
        stepviews: StepviewReducers,
        services: ServiceReducers,
        serviceContainers: ServiceContainerReducers,
        mails: MailReducers,
        mail_logs: MailLogReducers,
        widgets: WidgetReducers,
        conditions: ConditionReducers,
        branches: BranchReducers,
        stepview_widgets: StepviewWidgetReducers,
        packages: PackageReducers,
        // port: PortReducers
        central_instances: CentralInstanceReducers,
        service_migrations: ServiceMigrationReducers,
        metabase: MetabaseReducers
    }),
    composeEnhancers(
        applyMiddleware(
            routerMiddleware,
            sagaMiddleware,
            StateChangeMiddleware
        ),
        sentryReduxEnhancer,
    )
);

export const history = createReduxHistory(store);

export type RootState = ReturnType<typeof store.getState>

sagaMiddleware.run(RootSagas);

export default store;