import { HistoryResponseModel, MetabaseListResultModel, MetabaseRetrieveListModel } from "api";
import { MetabaseHistoryListRequest } from "api/apis/MetabaseApi";
import { MetabaseCreateSuccessModel } from "api/models/MetabaseCreateSuccessModel";
import Instance from "api/override/InstanceModel";
import ActionTypes from "config/ActionTypes";
import BaseReducerAction, { BaseSagaListResponse } from "reducers/interface/ReducerAction";

export interface MetabaseCreate extends BaseReducerAction {
    type: ActionTypes.API_METABASE_CREATE;
    params: string;
}

export interface MetabaseCreateSuccess extends BaseSagaListResponse<MetabaseCreateSuccessModel> {
    type: ActionTypes.API_METABASE_CREATE_SUCCESS;
}

export interface MetabaseDelete extends BaseReducerAction {
    type: ActionTypes.API_METABASE_DELETE;
    params: number;
}

export interface MetabaseDeleteSuccess extends BaseReducerAction {
    type: ActionTypes.API_METABASE_DELETE_SUCCESS;
}

export interface MetabaseRetrieve extends BaseReducerAction {
    type: ActionTypes.API_METABASE_RETRIEVE;
    params: string;
}

export interface MetabaseRetrieveSuccess extends BaseSagaListResponse<MetabaseRetrieveListModel> {
    type: ActionTypes.API_METABASE_RETRIEVE_SUCCESS
}

export interface MetabaseRetrieveDashboard extends BaseReducerAction {
    type: ActionTypes.API_METABASE_RETRIEVE_DASHBOARD;
    params: string;
}

export interface MetabaseRetrieveDashboardSuccess extends BaseSagaListResponse<MetabaseRetrieveListModel> {
    type: ActionTypes.API_METABASE_RETRIEVE_DASHBOARD_SUCCESS
}

export interface MetabaseHistoryList extends BaseReducerAction {
    type: ActionTypes.API_METABASE_HISTORY_LIST;
    params: MetabaseHistoryListRequest;
}

export interface MetabaseHistoryListSuccess extends BaseSagaListResponse<HistoryResponseModel> {
    type: ActionTypes.API_METABASE_HISTORY_LIST_SUCCESS;
}

export interface MetabaseClearListParams {
    force?: boolean;
}

export interface MetabaseClearList extends BaseReducerAction {
    type: ActionTypes.API_METABASE_LIST_CLEAR;
    params?: MetabaseClearListParams;
}

export default class MetabaseActions {

    static CreateMetabaseCreate(params: Instance): MetabaseCreate {
        return {
            type: ActionTypes.API_METABASE_CREATE,
            params: params.uuid
        }
    }

    static CreateMetabaseCreateSuccess(params: MetabaseCreateSuccessModel): MetabaseCreateSuccess {
        return {
            type: ActionTypes.API_METABASE_CREATE_SUCCESS,
            params: params
        }
    }

    static CreateMetabaseDelete(params: number): MetabaseDelete {
        return {
            type: ActionTypes.API_METABASE_DELETE,
            params: params
        }
    }

    static CreateMetabaseDeleteSuccess(): MetabaseDeleteSuccess {
        return {
            type: ActionTypes.API_METABASE_DELETE_SUCCESS
        }
    }

    static CreateMetabaseRetrieve(params: Instance): MetabaseRetrieve {
        return {
            type: ActionTypes.API_METABASE_RETRIEVE,
            params: params.uuid
        }
    }

    static CreateMetabaseRetrieveSuccess(params: MetabaseRetrieveListModel): MetabaseRetrieveSuccess {
        return {
            type: ActionTypes.API_METABASE_RETRIEVE_SUCCESS,
            params: params
        }
    }

    static CreateMetabaseRetrieveDashboard(params: Instance): MetabaseRetrieveDashboard {
        return {
            type: ActionTypes.API_METABASE_RETRIEVE_DASHBOARD,
            params: params.uuid
        }
    }

    static CreateMetabaseRetrieveDashboardSuccess(params: MetabaseListResultModel): MetabaseRetrieveDashboardSuccess {
        return {
            type: ActionTypes.API_METABASE_RETRIEVE_DASHBOARD_SUCCESS,
            params: params
        }
    }

    static CreateMetabaseHistoryList(params: MetabaseHistoryListRequest): MetabaseHistoryList {
        return {
            type: ActionTypes.API_METABASE_HISTORY_LIST,
            params: params
        }
    }

    static CreateMetabaseHistoryListSuccess(params: HistoryResponseModel): MetabaseHistoryListSuccess {
        return {
            type: ActionTypes.API_METABASE_HISTORY_LIST_SUCCESS,
            params: params
        }
    }

    static CreateClearMetabaseList(force: boolean = false): MetabaseClearList {
        return {
            type: ActionTypes.API_METABASE_LIST_CLEAR,
            params: {force: force}
        }   
    }

}