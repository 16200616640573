import { Button, Grid, Link } from '@mui/material';
import Instance from 'api/override/InstanceModel';
import Metabase from 'api/override/MetabaseModel';
import TableList from 'components/TableList';
import ActionTypes from 'config/ActionTypes';
import useTableDelegateHook from 'hooks/TableDelegateHook';
import i18next from 'i18next';
import TableColumn from 'models/table/TableColumn';
import { TableParams, TableParamsSorting } from 'models/table/TableParams';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MetabaseActions from 'reducers/types/Metabase';
import NotificationCenter, { NCListener } from 'services/NotificationCenter';
import { RootState } from 'store/AppStore';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';

interface MetabaseTabProps {
    model: Instance;
}

const MetabaseTab = (props: MetabaseTabProps) => {
    const [tableParams] = useState<TableParams>(new TableParams(25, 0, new TableParamsSorting('created_at', 'desc'), '', []))
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const dispatch = useDispatch();
    const metabaseReducer = useSelector((state: RootState) => state.metabase)
    const appReducer = useSelector((state: RootState) => state.app)
    const { t } = useTranslation();
    const onClick = () => {
        dispatch(MetabaseActions.CreateMetabaseCreate(props.model));
    }
    const onDelete = () => {
        dispatch(MetabaseActions.CreateMetabaseDelete(metabaseReducer.selectedObject?.id));
    }
    const delegate = useTableDelegateHook(tableParams, {
        getData() {
            dispatch(MetabaseActions.CreateMetabaseRetrieveDashboard(props.model));
            dispatch(MetabaseActions.CreateMetabaseRetrieve(props.model));
        },
        onDetails: (item: Metabase) => {
            window.open(`https://metabase.bumbal.eu/dashboard/${item.id}-${item.name?.toLowerCase()}?uuid=`, '_blank');
        }
    });
    const onNodeReceive = (action) => {
        if (action.params?.status === 'finished') {
            delegate.getData()
        }
    }
    useEffect(() => {
        const ncDelete = new NCListener(ActionTypes.NODE_METABASE_USER_CREATE, onNodeReceive)
        const ncCreate = new NCListener(ActionTypes.NODE_METABASE_USER_DELETE, onNodeReceive)
        const ncCloseDialog = new NCListener(ActionTypes.API_METABASE_DELETE_SUCCESS, () => setIsDeleteModalOpen(false))
        const listeners = [ncDelete, ncCreate, ncCloseDialog];
        NotificationCenter.default.addNCListeners(listeners)
        return () => {NotificationCenter.default.removeListeners(listeners)}
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const columns = [
        new TableColumn({ key: 'name', isSortable: false, label: i18next.t('instance.name') }),
        new TableColumn({ key: 'collection_name', isSortable: false, label: i18next.t('metabase.collection_name'), customValue: (row) => {
            return row.collection_name?.toLowerCase()  === props.model.name?.toLowerCase() ? '' : row.collection_name
        }, }),
    ];
    return (
        <Grid item xs={12}>
            <TableList
                hasDetailsAction
                columns={columns}
                count={metabaseReducer.collection?.count_filtered}
                listItems={metabaseReducer.collection?.items}
                isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_METABASE_RETRIEVE_DASHBOARD)}
                tableParams={tableParams}
                delegate={delegate}
            />
            { appReducer.isFetchingData.get(ActionTypes.API_METABASE_RETRIEVE_DASHBOARD) ? (
                null
            ) : (<>
                {!metabaseReducer.collection.items.bbIsEmpty() ? (
                    <Grid item xs={12} style={{ display: 'flex', justifyContent: 'space-between', padding: '16px' }}>
                        <Button variant='contained' color='error' onClick={() => setIsDeleteModalOpen(true)} disabled={metabaseReducer.selectedObject == null}>
                            {t('metabase.delete_collection')}
                        </Button>
                        <Button component={Link} variant='contained' href={`https://metabase.bumbal.eu/collection/${metabaseReducer.collection.items[0].collection_id}-${props.model.domain}`} target='_blank'>
                            {t('metabase.open_collection')}
                        </Button>
                    </Grid>
                ) : (
                    <Grid item xs={12} style={{ textAlign: 'right', width: '100%', padding: '16px' }}>
                        <Button onClick={onClick} variant='contained' style={{width: '100%'}}>
                            {t('metabase.create_collection')}
                        </Button>
                    </Grid>
                )}
            </>)}
            { isDeleteModalOpen ? (
                <ConfirmDeleteDialog
                    open
                    title={t('global.title.delete_confirm_model', {model: (t('metabase.model')).toLowerCase()})}
                    onConfirm={onDelete}
                    onModalClosePress={() => setIsDeleteModalOpen(false)}
                />
            ) : (null)}
        </Grid>
    )
}

export default MetabaseTab;