/* tslint:disable */
/* eslint-disable */
/**
 * Bumbal BackOffice Backend
 * Bumbal BackOffice Backend Management API
 *
 * The version of the OpenAPI document: 1.1.0
 * Contact: gerb@bumbal.eu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues, BaseModel, StringDateInterface } from '../runtime';

export interface MetabaseFilterModelInterface {
    id?: number;
    uuid?: string;
}

/**
 * 
 * @export
 * @interface MetabaseFilterModel
 */
export class MetabaseFilterModel extends BaseModel<MetabaseFilterModelInterface> {

    /**
     * Default ID filter so OpenAPI generates files
     * @type {number}
     * @memberof MetabaseFilterModel
     */
    id?: number;
    /**
     * The UUID of the intance to filter dashboards for
     * @type {string}
     * @memberof MetabaseFilterModel
     */
    uuid?: string;

    constructor(data?: MetabaseFilterModelInterface) {
        super(data);
        Object.assign(this, data ?? {});
    }

    static toJson(initValue: MetabaseFilterModel, value: StringDateInterface<MetabaseFilterModelInterface>): { [property: string]: any } {
        return value;
    }

}

export function MetabaseFilterModelFromJSON(json: any): MetabaseFilterModel {
    return MetabaseFilterModelFromJSONTyped(json, false);
}

export function MetabaseFilterModelFromJSONTyped(json: any, ignoreDiscriminator: boolean): MetabaseFilterModel {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    let createClass = MetabaseFilterModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    return new createClass({
        
        'id': !exists(json, 'id') ? undefined : Number(json['id']),
        'uuid': !exists(json, 'uuid') ? undefined : json['uuid'],
    });
}

export function MetabaseFilterModelToJSON(value?: MetabaseFilterModel | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    let createClass = MetabaseFilterModel;
    try {
        let localClass = require.context('../override', true)('./MetabaseFilterModel');
        if (localClass != null) {
            createClass = localClass.default;
        }
    } catch (e) {}
    const model = {
        
        'id': value.id,
        'uuid': value.uuid,
    } as StringDateInterface<MetabaseFilterModel>;
    return createClass.toJson(value, model);
}


