import ActionTypes from "config/ActionTypes";
import BaseReducerAction from "./interface/ReducerAction";
import MetabaseState from "./interface/MetabaseState";
import { MetabaseDashboardModel, MetabaseListResultModel } from "api";
import Metabase from "api/override/MetabaseModel";

const initialState: MetabaseState = {
    collection: {
        items: [],
        count_limited: 0,
        count_filtered: 0,
        count_unfiltered: 0,
    },
    selectedObject: undefined,
    error: undefined,
    history: {
        items: [],
        count_filtered: 0,
        count_unfiltered: 0,
    },
    timestampLastList: 0,
};

export function MetabaseReducers(state = initialState, action: BaseReducerAction): MetabaseState {
    switch (action.type) {
        case ActionTypes.API_METABASE_RETRIEVE_DASHBOARD:
        case ActionTypes.API_METABASE_CREATE:
        case ActionTypes.API_METABASE_DELETE:
        case ActionTypes.API_METABASE_RETRIEVE:
            return {
                ...state,
                error: undefined
            };

        case ActionTypes.API_METABASE_CREATE_SUCCESS:
            return {
                ...state,
            };
        case ActionTypes.API_METABASE_CREATE_FAILURE:
        case ActionTypes.API_METABASE_RETRIEVE_DASHBOARD_FAILURE:
        case ActionTypes.API_METABASE_RETRIEVE_FAILURE:
        case ActionTypes.API_METABASE_DELETE_FAILURE:
            return {
                ...state,
                error: action.params
            }
        
        case ActionTypes.API_METABASE_RETRIEVE_DASHBOARD_SUCCESS:
            return {
                ...state,
                collection: {
                    ...action.params as MetabaseListResultModel,
                    items: action.params.items as MetabaseDashboardModel[]
                },
                error: undefined
            };
        
        case ActionTypes.API_METABASE_RETRIEVE_SUCCESS:
            return {
                ...state,
                selectedObject: action.params as Metabase,
            }

        case ActionTypes.INSTANCE_VIEW_CLEAR_CONNECTORS:
            return {
                ...state,
                collection: initialState.collection,
                selectedObject: initialState.selectedObject,
                error: undefined
            }
        
        default:
            return state;
    }
}