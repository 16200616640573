import React, { Fragment } from 'react';
import { withRouter } from 'utils/withRouter';
import { withTranslation } from "react-i18next";
import {
    Card,
    Container,
    Grid,
    CardContent,
    Chip
} from "@mui/material";
import ActionTypes from "config/ActionTypes";
import { withSnackbar } from "notistack";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import CircularProgress from "@mui/material/CircularProgress/CircularProgress";
import { ViewProps } from 'components/base/BasePageComponent';
import Instance from 'api/override/InstanceModel';
import NotificationCenter from 'services/NotificationCenter';
import InstanceActions, { InstanceTriggerJobParams } from 'reducers/types/Instance';
import { FileUploadModel, InstanceAdminUpdate, InstanceAdminUpdateRequest, InstanceAuthenticateCreateTokenRequest, InstanceAuthenticateModel, InstanceConnectorListRequest, InstanceCreateSuccessModel, InstanceDeleteRequest, InstanceServiceListRequest, InstanceSetImageVersionModel, InstanceSetImageVersionRequest, InstanceUpdateRequest } from 'api';
import InstanceCreateForm from 'views/CreationForms/InstanceCreateForm';
import { BaseAction } from 'reducers/interface/ReducerAction';
import TypeDeleteDialog from 'views/Dialog/TypeDeleteDialog';
import ProcessProgress from './components/ProcessProgress';
import NCNotification from 'models/NCNotification';
import InstanceViewStyles from 'styles/InstanceViewStyles';
import i18next from 'i18next';
import InstanceDetails from './components/InstanceDetails';
import InstanceState from 'reducers/interface/InstanceState';
import TabManager, { TabView } from 'components/tabs/TabManager';
import ConnectorContainerState from 'reducers/interface/ConnectorContainerState';
import ConnectorContainerActions, { ConnectorContainerRetrieveSuccess, ConnectorContainerSetConfigParams, ConnectorContainerTriggerJobParams, ConnectorContainerUpdateParams, SetConfigFilesParams } from 'reducers/types/ConnectorContainer';
import ConnectorContainerCreateDialog from 'views/Dialog/ConnectorContainerCreateDialog';
import TableViewDelegate from 'interface/TableViewDelegate';
import { tableConnect } from '../../components/base/BaseTableViewComponent';
import TableList from 'components/TableList';
import TableColumn from 'models/table/TableColumn';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ConnectorContainerConfigDialog from 'views/Dialog/ConnectorContainerConfigDialog';
import CustomActionButton, { CustomIconButton } from 'models/table/CustomActionButton';
import ConnectorContainerController from 'controllers/ConnectorContainerController';
import AppState from 'reducers/interface/AppState';
import PublishIcon from '@mui/icons-material/Publish';
import ConnectorContainerFileUploadModal from 'views/Dialog/ConnectorContainerFileUploadModal';
import ConfigFileUpload from 'interface/ConfigFileUpload';
import ConnectorContainer from 'api/override/ConnectorContainerModel';
import { ConnectorContainerTableMenuButton } from './components/ConnectorContainerMenu';
import InstanceMenu from './components/InstanceMenu';
import ConfirmDeleteDialog from 'views/Dialog/ConfirmDeleteDialog';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import { Details } from 'mdi-material-ui';
import ConnectorContainerDocumentationModal from 'views/Connectors/components/ConnectorContainerDocumentationModal';
import ReloadDataButton from 'components/ReloadDataButton';
import InstanceSetImageVersionDialog from 'views/Dialog/InstanceSetImageVersionDialog';
import HistoryTab from 'components/tabs/HistoryTab';
import ConnectorContainerHistorySelect from './components/ConnectorContainerHistorySelect';
import ProcessLogsTab from 'components/tabs/ProcessLogsTab';
import DispatchTableViewComponent, { DispatchTableViewState } from 'components/DispatchTableViewComponent';
import Tasks from 'config/Tasks';
import TokenUtil from 'utils/TokenUtil';
import InstanceDetailsDialog from 'views/Dialog/InstanceDetailsDialog';
import DetailsViewHeader from 'components/DetailsViewHeader';
import ButtonStyled from 'components/styled/ButtonStyled';
import ConnectorContainerURLButton from 'components/buttons/ConnectorContainerURLButton';
import InstanceTrialsTab from './components/InstanceTrialsTab';
import ServiceContainer from 'api/override/ServiceContainerModel';
import ServiceContainerActions, { ServiceContainerRetrieveSuccess, ServiceContainerUpdateParams } from 'reducers/types/ServiceContainer';
import InstanceServiceContainersList from './components/InstanceServiceContainersList';
import InstanceServiceContainerHistorySelect from './components/InstanceServiceContainerHistorySelect';
import ServiceContainerCreateDialog from 'views/Dialog/ServiceContainerCreateDialog';
import ServiceContainerState from 'reducers/interface/ServiceContainerState';
import ServiceContainerDocumentationModal from 'views/Services/components/ServiceContainerDocumentationModal';
import ImageVersionColumn from 'components/table_columns/ImageVersionColumn';
import { withStyles } from 'tss-react/mui';
import InstanceSettings from './components/InstanceSettings';
import InstanceAdminPasswordUpdateDialog from 'views/Dialog/InstanceAdminPasswordUpdateDialog';
import InstanceAdminPassword from 'models/InstanceAdminPassword';
import InstanceAuthenticateModal from './components/InstanceAuthenticateModal';
import MetabaseTab from './components/MetabaseTab';

interface StateProps {
    instanceReducer: InstanceState;
    connectorContainerReducer: ConnectorContainerState;
    appReducer: AppState;
    serviceContainerReducer: ServiceContainerState;
}
interface DispatchProps {
    createInstance: (params: Instance) => void;
    retrieveInstance: (params: string) => void;
    updateInstance: (params: InstanceUpdateRequest) => void;
    deleteInstance: (params: InstanceDeleteRequest) => void;
    triggerTaskInstance: (params: InstanceTriggerJobParams) => void;
    listConnectors: (params: InstanceConnectorListRequest) => void;
    listServiceContainers: (params: InstanceServiceListRequest) => void;
    createConnector: (params: ConnectorContainer) => void;
    createServiceContainer: (params: ServiceContainer) => void;
    deleteConnector: (params: string) => void;
    getConnectorConfig: (params: string) => void;
    clearConnectors: () => void;
    updateConnectorContainer: (params: ConnectorContainerUpdateParams) => void;
    updateServiceContainer: (params: ServiceContainerUpdateParams) => void;
    instanceSetImageVersions: (params: InstanceSetImageVersionRequest) => void;
    retrieveConnectorContainer: (params: string) => void;
    retrieveServiceContainer: (params: string) => void;
    triggerTasksCC: (params: ConnectorContainerTriggerJobParams) => void;
    clearInstances: (params: boolean) => void;
    setConnectorConfig: (params: ConnectorContainerSetConfigParams) => void;
    setConnectorConfigFiles: (params: SetConfigFilesParams) => void;
    instanceAdminPasswordUpdate: (params: InstanceAdminUpdateRequest) => void;
    instanceCreateToken: (params: InstanceAuthenticateCreateTokenRequest) => void;
}
type Props = StateProps & DispatchProps & ViewProps;
interface State extends DispatchTableViewState {
    instance?: Instance;
    connectorContainer?: ConnectorContainer;
    serviceContainer?: ServiceContainer;
    historyServiceContainer?: ServiceContainer;
    historyConnectorContainer?: ConnectorContainer;
    updateProgressOpen: boolean;
    updateProgressDone: boolean;
    updateHasError: boolean;
    updateErrorMessage: string;
    notifications: NCNotification[];
    isExpanded: boolean;
    isDeleteInstanceModalOpen: boolean;
    isUpdateInstanceModalOpen: boolean;
    isDeleteConnectorModalOpen: boolean;
    isConfigContainerModalOpen: boolean;
    isConnectorCreateModalOpen: boolean;
    isServiceContainerCreateModalOpen: boolean;
    isFileUploadModalOpen: boolean;
    isCCDocumentationModalOpen: boolean;
    isSCDocumentationModalOpen: boolean;
    isInstanceSetImageVersionsModalOpen: boolean;
    isAdminUpdatePasswordModalOpen: boolean;
    isInstanceAuthenticateModalOpen: boolean;
    runningProcessIds: string[];
    triggerOptions: string[];
    isOldRequested: boolean;
}
class InstanceView extends DispatchTableViewComponent<Props, State> implements TableViewDelegate {
    columns: TableColumn[];
    customActionButtons: CustomActionButton[]
    constructor(props) {
        super(props);
        this.state = {
            instance: null,
            connectorContainer: null,
            serviceContainer: null,
            historyConnectorContainer: null,
            historyServiceContainer: null,
            updateProgressOpen: false,
            updateProgressDone: false,
            updateHasError: false,
            updateErrorMessage: null,
            notifications: [],
            isExpanded: false,
            isDeleteInstanceModalOpen: false,
            isUpdateInstanceModalOpen: false,
            isDeleteConnectorModalOpen: false,
            isConfigContainerModalOpen: false,
            isConnectorCreateModalOpen: false,
            isServiceContainerCreateModalOpen: false,
            isFileUploadModalOpen: false,
            isCCDocumentationModalOpen: false,
            isSCDocumentationModalOpen: false,
            isInstanceSetImageVersionsModalOpen: false,
            isAdminUpdatePasswordModalOpen: false,
            isInstanceAuthenticateModalOpen: false,
            runningProcessIds: [],
            triggerOptions: [i18next.t('global.button.create'), i18next.t('global.button.delete'), i18next.t('global.button.backup'), i18next.t('global.button.redeploy')],
            isOldRequested: false,
            selected: []
        };
        this.customActionButtons = [
            new CustomIconButton('documentation', i18next.t('connector_container.view.docs_img_version_tooltip'), 'documentation', (name?: string, row?: ConnectorContainer) => {
                this.setState({ isCCDocumentationModalOpen: true, connectorContainer: row })
            }, Details),
            new ConnectorContainerURLButton()
        ]
        const token: TokenUtil = TokenUtil.getTokenUtil()
        if (token.isAccountManager()) {
            this.customActionButtons.push(
                new CustomIconButton('upload', i18next.t('connector_container.view.table_upload'), 'upload', (name?: string, row?: ConnectorContainer) => {
                    this.setState({ isFileUploadModalOpen: true, connectorContainer: row })
                }, PublishIcon)
            )
        }
        this.customActionButtons.push(
            new CustomIconButton('config', i18next.t('connector_container.view.config'), 'config', (name?: string, row?: ConnectorContainer) => {
                this.onConfig(row)
            }, SettingsApplicationsIcon),
            new ConnectorContainerTableMenuButton()
        )
        this.setFailuresToListenTo([
            ActionTypes.API_INSTANCE_RETRIEVE_FAILURE,
            ActionTypes.API_INSTANCE_CREATE_FAILURE,
            ActionTypes.API_INSTANCE_DELETE_FAILURE,
            ActionTypes.API_CONNECTOR_CONTAINER_CREATE_FAILURE,
            ActionTypes.API_CONNECTOR_CONTAINER_DELETE_FAILURE,
            ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG_FAILURE,
            ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_FAILURE,
            ActionTypes.API_SERVICE_CONTAINER_CREATE_FAILURE,
            ActionTypes.API_SERVICE_CONTAINER_DELETE_FAILURE,
            ActionTypes.API_SERVICE_CONTAINER_UPDATE_FAILURE,
            ActionTypes.API_SERVICE_CONTAINER_TRIGGER_JOB_FAILURE,
            ActionTypes.API_INSTANCE_UPDATE_FAILURE,
            ActionTypes.API_INSTANCE_TRIGGER_JOB_FAILURE,
            ActionTypes.API_INSTANCE_SET_IMAGE_VERSION_FAILURE,
            ActionTypes.API_INSTANCE_UPDATE_ADMIN_PASSWORD_FAILURE,
            ActionTypes.API_INSTANCE_USERS_RETRIEVE_FAILURE,
            ActionTypes.API_INSTANCE_RETRIEVE_SETTINGS_FAILURE
        ]);
    }
    configureColumns = () => {
        this.columns = [
            new TableColumn({ key: 'name', isSortable: false, label: i18next.t('connector.name'), customValue: (row: ConnectorContainer) => row.connector?.name }),
            new TableColumn({ key: 'status', isSortable: false, label: i18next.t('connector.status')}),
            new ImageVersionColumn()
        ];
    }
    componentDidMount() {
        super.componentDidMount();
        this.loadData()
    };
    registerNotificationCenter() {
        super.registerNotificationCenter();
        NotificationCenter.default.addListener(
            this,
            ActionTypes.API_INSTANCE_RETRIEVE_SUCCESS,
            this.onRetrieveSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_INSTANCE_CREATE_SUCCESS,
            this.onCreateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_INSTANCE_DELETE_SUCCESS,
            this.onDeleteSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CONNECTOR_CONTAINER_CREATE_SUCCESS,
            this.onContainerCreateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CONNECTOR_CONTAINER_DELETE_SUCCESS,
            this.onContainerDeleteSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CONNECTOR_CONTAINER_SET_CONFIG_SUCCESS,
            this.onContainerConfigSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG_SUCCESS,
            (action) => this.onGetConfigSuccess(action?.params?.data)
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CONNECTOR_CONTAINER_UPDATE_SUCCESS,
            this.onConnectorContainerUpdateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_INSTANCE_UPDATE_SUCCESS,
            this.onInstanceUpdateSuccess
        )
        NotificationCenter.default.addListener(
            this, ActionTypes.API_CONNECTOR_CONTAINER_RETRIEVE_SUCCESS,
            this.onConnectorContainerRetrieveSuccess
        )
        NotificationCenter.default.addListener(
            this, ActionTypes.API_SERVICE_CONTAINER_RETRIEVE_SUCCESS,
            this.onServiceContainerRetrieveSuccess
        )
        NotificationCenter.default.addListener(
            this, ActionTypes.API_INSTANCE_SET_IMAGE_VERSION_SUCCESS,
            this.onSetImageSuccess
        )
        NotificationCenter.default.addListener(
            this, ActionTypes.API_INSTANCE_AUTHENTICATE_CREATE_TOKEN_SUCCESS,
            this.onCreateTokenSuccess
        )
        NotificationCenter.default.addListeners(
            this,
            Object.values(ActionTypes).filter((str: string) => str.includes('instance') || str.includes('container') || str.includes('metabase')),
            (action) => {
                if (action.params?.data?.meta?.instance_uuid === this.props.params.slug) {
                    this.setState({ notifications: this.state.notifications.concat(action) })
                    if (!this.state.runningProcessIds.includes(action?.params?.data?.process_token)) {
                        this.setState({ runningProcessIds: this.state.runningProcessIds.bbPrepend(action?.params?.data?.process_token) })
                    }
                    if (action.params?.status === 'finished') {
                        this.props.clearConnectors()
                        this.props.retrieveInstance(this.props.params.slug);
                    }
                }
            }
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_SERVICE_CONTAINER_CREATE_SUCCESS,
            this.onServiceCreateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_SERVICE_CONTAINER_DELETE_SUCCESS,
            this.onServiceDeleteSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_SERVICE_CONTAINER_UPDATE_SUCCESS,
            this.onServiceUpdateSuccess
        );
        NotificationCenter.default.addListener(
            this, ActionTypes.API_INSTANCE_UPDATE_ADMIN_PASSWORD_SUCCESS,
            this.onInstanceUpdateAdminPasswordSuccess
        );
    }
    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.clearConnectors()
    }
    reloadData = () => {
        if (this.isPathIncluded('logs')) {
            NotificationCenter.default.sendNotification({ type: ActionTypes.INSTANCE_VIEW_UPDATE_LOGS }, null);
        }
        else if (this.isPathIncluded('cc-history')) {
            NotificationCenter.default.sendNotification({ type: ActionTypes.API_CONNECTOR_CONTAINER_HISTORY_UPDATE }, null);
        }
        else if (this.isPathIncluded('history')) {
            NotificationCenter.default.sendNotification({ type: ActionTypes.API_INSTANCE_HISTORY_UPDATE }, null);
        } else if (this.isPathIncluded('trials')) {
            NotificationCenter.default.sendNotification({ type: ActionTypes.TRIAL_UPDATE_LIST }, null);
        }
        this.loadData();
    }
    loadData = () => {
        if (this.isDetailsView(this.props)) {
            this.props.retrieveInstance(this.props.params.slug);
            if (this.isPathIncluded('connectors') || this.isPathIncluded('services')) {
                this.getData()
            }
        }
    }
    getData = () => {
        if (this.state.instance == null || this.state.instance?.deleted_at != null) {
            return
        }
        if (this.isDetailsView(this.props) && this.isPathIncluded('connectors')) {
            this.props.listConnectors({ uuid: this.props.params.slug, instanceConnectorRetrieveListModel: this.getParams().getApiFilter() });
        }
        if (this.isDetailsView(this.props) && this.isPathIncluded('services')) {
            this.props.listServiceContainers({ uuid: this.props.params.slug, serviceContainerRetrieveListModel: this.getParams().getApiFilter() });
        }
    }
    getItemCount = (): number => {
        return this.props.instanceReducer.connectors?.items.length ?? 0
    }
    onCreateSuccess = (action: BaseAction) => {
        const instanceSuccess: InstanceCreateSuccessModel = action.params
        this.props.navigate(`/instances/${instanceSuccess.uuid}`)
        const newInstance = new Instance(instanceSuccess.model);
        newInstance.isLaunching = true;
        this.setState({
            instance: newInstance
        })
        this.props.clearInstances(true)
    }
    onInstanceUpdateSuccess = (action: BaseAction) => {
        this.dialogClosed();
        const instanceSuccess: InstanceCreateSuccessModel = action.params
        this.setState({
            instance: new Instance(instanceSuccess.model)
        })
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', { model: i18next.t('instance.model') }), { variant: 'success' });
        this.props.clearInstances(true)
    }
    onConnectorContainerUpdateSuccess = () => {
        this.getData();
        this.dialogClosed();
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', { model: i18next.t('connector_container.documentation') }), { variant: 'success' });
    }
    onConnectorContainerRetrieveSuccess = (action: BaseAction) => {
        this.setState({ historyConnectorContainer: (action as ConnectorContainerRetrieveSuccess).params })
    }
    onServiceContainerRetrieveSuccess = (action: BaseAction) => {
        this.setState({ historyServiceContainer: (action as ServiceContainerRetrieveSuccess).params })
    }
    onRetrieveSuccess = (action: BaseAction) => {
        this.setState({ instance: action.params }, () => {
            if (this.state.instance?.deleted_at == null) {
                this.getData()
            }
            this.updateTabTitle(this.state.instance.name)
        })
    }
    onContainerCreateSuccess = () => {
        this.props.enqueueSnackbar(i18next.t('global.notification.create_process_started_model', { model: i18next.t('connector_container.model') }), { variant: 'success' })
        this.getData()
    }
    onContainerDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_process_started_model', { model: i18next.t('connector_container.model') }), { variant: 'success' })
        this.getData()
    }
    onGetConfigSuccess = (data) => {
        if (data?.allowed_input == null || data?.allowed_input?.length === 0) {
            this.props.enqueueSnackbar(i18next.t('connector_container.view.notification_not_ready'), { variant: 'info' })
        } else {
            this.setState({
                isConfigContainerModalOpen: true
            })
        }
    }
    onCreateTokenSuccess = (action: BaseAction) => {
        const token: string = action.params.data?.['payload']
        window.open(this.state.instance.getPasswordlessUrl(this.state.isOldRequested, token))
    }
    onContainerConfigSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.config_success_model', { model: i18next.t('connector_container.model') }), { variant: 'success' })
        this.getData()
    }
    onServiceCreateSuccess = () => {
        this.props.enqueueSnackbar(i18next.t('global.notification.create_process_started_model', { model: i18next.t('service_container.model') }), { variant: 'success' })
        this.getData()
    }
    onSetImageSuccess = (action: BaseAction) => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', {model: i18next.t('image_versions.model')}), {variant: 'success'})
        this.loadData()
    }
    onServiceDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_process_started_model', { model: i18next.t('service_container.model') }), { variant: 'success' })
        this.getData()
    }
    onServiceUpdateSuccess = () => {
        this.getData();
        this.dialogClosed();
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', { model: i18next.t('connector_container.documentation') }), { variant: 'success' });
    }
    onDeleteSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.delete_process_started_model', { model: i18next.t('instance.model') }), { variant: 'success' })
    }
    onInstanceUpdateAdminPasswordSuccess = () => {
        this.dialogClosed()
        this.props.enqueueSnackbar(i18next.t('global.notification.update_success_model', { model: i18next.t('instance.admin_password') }), { variant: 'success' })
        this.loadData()
    }
    onInstanceAuthAsUser = (isOld: boolean) => {
        this.setState({isOldRequested: isOld, isInstanceAuthenticateModalOpen: true})
    }
    onDelete = (connectorContainer: ConnectorContainer) => {
        this.setState({
            connectorContainer: connectorContainer,
            isDeleteConnectorModalOpen: true,
        });
    };
    onDetails = (connectorContainer: ConnectorContainer) => {
        this.props.getConnectorConfig(connectorContainer?.uuid)
        this.setState({
            connectorContainer: connectorContainer
        })
    }
    onConfig = (row: ConnectorContainer) => {
        this.setState({ isConfigContainerModalOpen: true, instance: row.instance as Instance, connectorContainer: row })
        this.props.getConnectorConfig(row?.uuid)
    }
    onDeleteInstance = () => {
        this.setState({
            isDeleteInstanceModalOpen: true,
        });
    }
    onInstanceUpdate = () => {
        this.setState({
            isUpdateInstanceModalOpen: true,
        });
    }
    onContainerCreate = () => {
        this.setState({
            isConnectorCreateModalOpen: true,
        })
    }
    onServiceContainerCreate = () => {
        this.setState({
            isServiceContainerCreateModalOpen: true,
        })
    }
    dialogClosed = () => {
        this.setState({
            isDeleteInstanceModalOpen: false,
            isUpdateInstanceModalOpen: false,
            isDeleteConnectorModalOpen: false,
            isConfigContainerModalOpen: false,
            isConnectorCreateModalOpen: false,
            isServiceContainerCreateModalOpen: false,
            isFileUploadModalOpen: false,
            isCCDocumentationModalOpen: false,
            isSCDocumentationModalOpen: false,
            isInstanceSetImageVersionsModalOpen: false,
            isAdminUpdatePasswordModalOpen: false,
            isInstanceAuthenticateModalOpen: false
        })
    }
    handleMultiRedeploy(selected: string[]): void {
        this.props.triggerTasksCC({ uuid: selected.bbFirst(), task: Tasks.MULTI_REDEPLOY, uuids: selected.bbRemoveFirstItem() })
    }
    deleteInstance = (deletionReason: string) => {
        this.props.deleteInstance({ uuid: this.state.instance.uuid, deletionReason: deletionReason });
    };
    onInstanceSetImageVersion = () => {
        this.setState({ isInstanceSetImageVersionsModalOpen: true })
    }
    onInstanceMigrate = () => {
        if (this.state.instance != null) {
            this.props.triggerTaskInstance({
                uuid: this.state.instance.uuid,
                task: Tasks.MIGRATION
            });
        }
    }
    onInstanceUpdateAdminPassword = () => {
        this.setState({isAdminUpdatePasswordModalOpen: true})
    }
    generateToken = (isOldRequested: boolean) => {
        this.setState({isOldRequested: isOldRequested})
        this.props.instanceCreateToken({uuid: this.state.instance.uuid, instanceAuthenticateModel: {user_id: null} as InstanceAuthenticateModel})
    }
    deleteConnector = () => {
        this.props.deleteConnector(this.state.connectorContainer.uuid)
    }
    shouldRenderProgress = (): boolean => {
        return (this.state.notifications.length !== 0 && this.state.notifications.bbLast()?.params?.data?.meta?.instance_uuid === this.props.params.slug)
    }
    shouldRenderInstanceDetails = (): boolean => {
        return this.isDetailsView(this.props) && this.state.instance != null
    }
    shouldRenderCreateForm = () => {
        return this.isCreateView(this.props)
    }
    componentDidUpdate = (prevProps) => {
        const prevUuid: string = prevProps.params?.slug;
        const newUuid: string = this.props.params.slug;
        if (newUuid !== prevUuid) {
            this.props.retrieveInstance(newUuid);
            this.getData();
        }
    }
    onServiceContainerRowClick = (serviceContainer: ServiceContainer) => {
        this.setState({
            serviceContainer: serviceContainer,
            isSCDocumentationModalOpen: true
        })
    }
    renderView() {
        const { classes, instanceReducer, connectorContainerReducer, appReducer } = this.props;
        return (
            <React.Fragment>
                {!this.shouldRenderCreateForm() && <ReloadDataButton loadData={this.reloadData} />}
                <Backdrop className={classes.backdrop} open={appReducer.isFetchingData.get(ActionTypes.API_INSTANCE_RETRIEVE) || appReducer.isFetchingData.get(ActionTypes.API_INSTANCE_AUTHENTICATE_CREATE_TOKEN)}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Container maxWidth={"lg"}>
                    <Grid container spacing={3} alignItems="center" direction="row" justifyContent="center">
                        <Grid item xs={12} md={12} lg={9}>
                            <Card className={classes.root}>
                                {this.shouldRenderInstanceDetails() &&
                                    <div>
                                        <DetailsViewHeader
                                            model={i18next.t('instance.model')}
                                            title={this.state.instance?.name}
                                        >
                                            <Chip
                                                label={this.state.instance?.active ? i18next.t('instance.active') : i18next.t('instance.not_active')}
                                                className={`${classes.chip} ${this.state.instance?.active ? 'active' : 'inactive'}`}
                                            />
                                            {this.state.instance?.isDeleted() ?
                                                <Chip
                                                    label={i18next.t('instance.deleted')}
                                                    className={`${classes.chip} deleted`}
                                                />
                                            : null}
                                            {this.state.instance?.migrated ?
                                                <Chip
                                                    label={i18next.t('instance.migrated')}
                                                    className={`${classes.chip} migrated`}
                                                />
                                            : null}
                                            <div style={{ marginLeft: 'auto' }}>
                                                <InstanceMenu instance={this.state.instance} />
                                            </div>
                                        </DetailsViewHeader>
                                        <TabManager
                                            items={[
                                                {
                                                    title: i18next.t('general.detail'),
                                                    link: `/instances/${this.state.instance?.uuid}`,
                                                    view: (
                                                        <InstanceDetails
                                                            instance={this.state.instance}
                                                            onInstanceDelete={this.onDeleteInstance}
                                                            onInstanceUpdate={this.onInstanceUpdate}
                                                            onSetImageVersion={this.onInstanceSetImageVersion}
                                                            onInstanceMigrate={this.onInstanceMigrate}
                                                            onInstanceUpdateAdminPassword={this.onInstanceUpdateAdminPassword}
                                                            onInterfaceClick={() => this.generateToken(false)}
                                                            onInterfaceButtonClick={() => this.onInstanceAuthAsUser(false)}
                                                            onOldInterfaceClick={() => this.generateToken(true)}
                                                            onOldInterfaceButtonClick={() => this.onInstanceAuthAsUser(true)}
                                                        />
                                                    )
                                                }, {
                                                    title: i18next.t('instance.settings'),
                                                    link: `/instances/${this.state.instance?.uuid}/settings`,
                                                    initialTab: this.isPathIncluded('settings'),
                                                    hidden: this.state.instance.isDeleted(),
                                                    view: (
                                                        <InstanceSettings instance={this.state.instance} />
                                                    )
                                                }, {
                                                    title: i18next.t('metabase.tab_name'),
                                                    link: `/instances/${this.state.instance?.uuid}/metabase`,
                                                    initialTab: this.isPathIncluded('metabase'),
                                                    hidden: this.state.instance.isDeleted(),
                                                    view: (
                                                        <MetabaseTab model={this.state.instance} />
                                                    )
                                                },
                                                {
                                                    title: i18next.t('process.model_plural'),
                                                    link: `/instances/${this.state.instance?.uuid}/logs`,
                                                    initialTab: this.isPathIncluded('logs'),
                                                    view: (
                                                        <ProcessLogsTab model={this.state.instance} />
                                                    )
                                                },
                                                {
                                                    title: i18next.t('connector_container.model'),
                                                    link: `/instances/${this.state.instance?.uuid}/connectors`,
                                                    initialTab: this.isPathIncluded('connectors'),
                                                    hidden: this.state.instance.isDeleted(),
                                                    view: (
                                                        <Grid item xs={12} >
                                                            <ButtonStyled
                                                                className={classes.button_add_container}
                                                                aria-label="instance"
                                                                startIcon={<AddCircleOutlineIcon />}
                                                                onClick={this.onContainerCreate}
                                                            >
                                                                {i18next.t('global.action.create_model', { model: i18next.t('connector_container.model') })}
                                                            </ButtonStyled>
                                                            <TableList
                                                                hasDeleteAction
                                                                selectableRows={true}
                                                                selected={this.state.selected}
                                                                columns={this.columns}
                                                                count={instanceReducer.connectors?.count_filtered}
                                                                listItems={instanceReducer.connectors?.items}
                                                                isRefreshing={appReducer.isFetchingData.get(ActionTypes.API_INSTANCE_LIST_CONNECTORS)}
                                                                customActions={this.customActionButtons}
                                                                delegate={this}
                                                                tableParams={this.getParams()}
                                                                idColumn={'uuid'}
                                                            />
                                                            {this.getRedeployView(i18next.t('global.action.trigger_model_redeploy', { model: i18next.t('connector_container.model') }))}
                                                        </Grid>
                                                    )
                                                },
                                                {
                                                    title: i18next.t('service_container.model'),
                                                    link: `/instances/${this.state.instance?.uuid}/services`,
                                                    initialTab: this.isPathIncluded('services'),
                                                    hidden: this.state.instance.isDeleted(),
                                                    view: (
                                                        <Grid item xs={12} >
                                                            <ButtonStyled
                                                                className={classes.button_add_container}
                                                                aria-label="instance"
                                                                startIcon={<AddCircleOutlineIcon />}
                                                                onClick={this.onServiceContainerCreate}
                                                            >
                                                                {i18next.t('global.action.create_model', { model: i18next.t('service_container.model') })}
                                                            </ButtonStyled>
                                                            <InstanceServiceContainersList instance={this.state.instance} onRowClick={this.onServiceContainerRowClick} appReducer={appReducer} />

                                                            {this.getRedeployView(i18next.t('global.action.trigger_model_redeploy', { model: i18next.t('service_container.model') }))}
                                                        </Grid>
                                                    )
                                                }
                                                , {
                                                    title: i18next.t('trials.model_plural'),
                                                    link: `/instances/${this.state.instance?.uuid}/trials`,
                                                    initialTab: this.isPathIncluded(`/instances/${this.state.instance?.uuid}/trials`),
                                                    view: (
                                                        <InstanceTrialsTab instance={this.state.instance} />
                                                    ),
                                                    hidden: true
                                                }, {
                                                    title: i18next.t('history.model'),
                                                    link: `/instances/${this.state.instance?.uuid}/history`,
                                                    initialTab: this.isPathIncluded(`/instances/${this.state.instance?.uuid}/history`),
                                                    view: (
                                                        <HistoryTab model={this.state.instance} />
                                                    )
                                                }, {
                                                    title: i18next.t('history.history_model', { model: i18next.t('connector_container.model') }),
                                                    link: `/instances/${this.state.instance?.uuid}/cc-history`,
                                                    initialTab: this.isPathIncluded('cc-history'),
                                                    hidden: this.state.instance.isDeleted(),
                                                    view: (
                                                        <>
                                                            <p style={{ textAlign: 'center', fontSize: 14, fontWeight: 300, marginTop: 25 }}>{i18next.t('history.select_model', { model: i18next.t('connector_container.model').toLowerCase() })}</p>
                                                            <ConnectorContainerHistorySelect
                                                                instanceUuid={this.state.instance?.uuid}
                                                                onContainerSelect={(ccUuid: string) => this.setState({
                                                                    historyConnectorContainer: null
                                                                }, () => this.props.retrieveConnectorContainer(ccUuid))}
                                                            />
                                                            {this.state.historyConnectorContainer != null &&
                                                                <HistoryTab model={this.state.historyConnectorContainer} />
                                                            }
                                                        </>
                                                    )
                                                },
                                                {
                                                    title: i18next.t('history.history_model', { model: i18next.t('service_container.model') }),
                                                    link: `/instances/${this.state.instance?.uuid}/sc-history`,
                                                    initialTab: this.isPathIncluded('sc-history'),
                                                    hidden: this.state.instance.isDeleted(),
                                                    view: (
                                                        <>
                                                            <p style={{ textAlign: 'center', fontSize: 14, fontWeight: 300, marginTop: 25 }}>{i18next.t('history.select_model', { model: i18next.t('service_container.model').toLowerCase() })}</p>
                                                            <InstanceServiceContainerHistorySelect
                                                                instanceUuid={this.state.instance?.uuid}
                                                                onContainerSelect={(scUuid: string) => this.setState({
                                                                    historyServiceContainer: null
                                                                }, () => this.props.retrieveServiceContainer(scUuid))}
                                                            />
                                                            {this.state.historyServiceContainer != null &&
                                                                <HistoryTab model={this.state.historyServiceContainer} />
                                                            }
                                                        </>
                                                    )
                                                }
                                            ]}
                                            onTabSwitch={(newTab: TabView) => {
                                                if (newTab.link.includes('connectors')) {
                                                    if (instanceReducer.connectors.items.length === 0 && !appReducer.isFetchingData.get(ActionTypes.API_INSTANCE_LIST_CONNECTORS)) {
                                                        this.props.listConnectors({ uuid: this.props.params.slug, instanceConnectorRetrieveListModel: this.getParams().getApiFilter() });
                                                    }
                                                }
                                                if (newTab.link.includes('services')) {
                                                    if (instanceReducer.collection.items.length === 0 && !appReducer.isFetchingData.get(ActionTypes.API_INSTANCE_LIST_SERVICE_CONTAINERS)) {
                                                        this.props.listServiceContainers({ uuid: this.props.params.slug, serviceContainerRetrieveListModel: this.getParams().getApiFilter() })
                                                    }
                                                }
                                            }}
                                        />
                                    </div>
                                }
                                {this.shouldRenderProgress() &&
                                    <CardContent>
                                        {this.state.runningProcessIds.map((processId) => {
                                            return (
                                                <ProcessProgress
                                                    key={processId}
                                                    notifications={this.state.notifications.filter((notif) => processId === notif.params.data.process_token)}
                                                    onFinish={(process: NCNotification) => {
                                                        this.props.enqueueSnackbar(i18next.t(`node.${process.params.data.process_name}`, { entity: process.params.data.meta?.name }) + ' ' + i18next.t('global.notification.process_finished'), { variant: 'success' })
                                                    }}
                                                />
                                            )
                                        })}
                                    </CardContent>
                                }
                                {this.shouldRenderCreateForm() &&
                                    <CardContent>
                                        <InstanceCreateForm
                                            onFormSubmit={(instance: Instance) => {
                                                this.props.createInstance(instance);
                                            }}
                                        />
                                    </CardContent>
                                }
                            </Card>
                            <TypeDeleteDialog
                                open={this.state.isDeleteInstanceModalOpen}
                                onModalClosePress={this.dialogClosed}
                                confirmValue={this.state.instance?.name}
                                onConfirm={(deletionReason: string) => this.deleteInstance(deletionReason)}
                                title={i18next.t('global.title.delete_confirm_model', { model: (i18next.t('instance.model')).toLowerCase() })}
                                hasDeleteReason
                            />
                            <InstanceDetailsDialog
                                open={this.state.isUpdateInstanceModalOpen}
                                onModalClosePress={this.dialogClosed}
                                instance={this.state.instance}
                                onModalSubmit={(instance: Instance) => {
                                    const formattedInstance: Instance = new Instance({ ...instance });
                                    if (instance.admin_password === "") {
                                        delete formattedInstance.admin_password
                                    }
                                    this.props.updateInstance({ uuid: this.state.instance.uuid, instanceModel: formattedInstance })
                                }}
                            />
                            <ConfirmDeleteDialog
                                open={this.state.isDeleteConnectorModalOpen}
                                onModalClosePress={this.dialogClosed}
                                onConfirm={this.deleteConnector}
                                title={i18next.t('global.title.delete_confirm_model', { model: `${this.state.connectorContainer?.connector?.name} ${this.state.connectorContainer?.getDeleteDialog()} on ${this.state.connectorContainer?.instance?.name}` })}
                            />
                            <ConnectorContainerCreateDialog
                                open={this.state.isConnectorCreateModalOpen}
                                instance={this.state.instance}
                                onModalClosePress={this.dialogClosed}
                                onModalSubmit={(connector: ConnectorContainer) => {
                                    this.props.createConnector(connector)
                                    this.dialogClosed()
                                }}
                            />
                            <InstanceAdminPasswordUpdateDialog
                                open={this.state.isAdminUpdatePasswordModalOpen}
                                instance={this.state.instance}
                                onModalClosePress={this.dialogClosed}
                                onModalSubmit={(val: InstanceAdminPassword) => {
                                    const args: InstanceAdminUpdateRequest = {
                                        uuid: this.state.instance.uuid,
                                        instanceAdminUpdate: new InstanceAdminUpdate({admin_password: val.confirmPassword})
                                    }
                                    this.props.instanceAdminPasswordUpdate(args)
                                    this.dialogClosed()
                                }}
                            />
                            <ServiceContainerCreateDialog
                                open={this.state.isServiceContainerCreateModalOpen}
                                instance={this.state.instance}
                                onModalClosePress={this.dialogClosed}
                                onModalSubmit={(service: ServiceContainer) => {
                                    this.props.createServiceContainer(service)
                                    this.dialogClosed()
                                }}
                            />
                            <InstanceSetImageVersionDialog
                                open={this.state.isInstanceSetImageVersionsModalOpen}
                                instance={this.state.instance}
                                onModalClosePress={this.dialogClosed}
                                onModalSubmit={(instance: Instance) => {
                                    const imageVersionModel = new InstanceSetImageVersionModel({
                                        core_image_version_id: instance?.core_image_version_id,
                                        ui_image_version_id: instance?.ui_image_version_id,
                                        tools_image_version_id: instance?.tools_image_version_id
                                    });
                                    this.props.instanceSetImageVersions({
                                        uuid: instance?.uuid,
                                        instanceSetImageVersionModel: imageVersionModel
                                    });
                                    this.dialogClosed()
                                }}
                            />
                            <ConnectorContainerFileUploadModal
                                open={this.state.isFileUploadModalOpen}
                                instance={this.state.instance}
                                connector={this.state.connectorContainer?.connector}
                                onModalClosePress={this.dialogClosed}
                                onModalSubmit={(upload: ConfigFileUpload) => {
                                    const fileToUpload: FileUploadModel = new FileUploadModel({
                                        file: upload.filename,
                                        base64: upload.file.base64
                                    })
                                    const fileArgs = {
                                        uuid: this.state.connectorContainer?.uuid,
                                        fileUploadModel: fileToUpload
                                    } as SetConfigFilesParams
                                    this.props.setConnectorConfigFiles(fileArgs)
                                    this.dialogClosed()
                                }}
                            />
                            <ConnectorContainerDocumentationModal
                                open={this.state.isCCDocumentationModalOpen}
                                connectorContainer={this.state.connectorContainer}
                                onModalClosePress={this.dialogClosed}
                                onModalSubmit={(connectorContainer: ConnectorContainer) => {
                                    this.props.updateConnectorContainer({
                                        uuid: this.state.connectorContainer?.uuid,
                                        ...connectorContainer
                                    })
                                }}
                            />
                            <ServiceContainerDocumentationModal
                                open={this.state.isSCDocumentationModalOpen}
                                serviceContainer={this.state.serviceContainer}
                                onModalClosePress={this.dialogClosed}
                                onModalSubmit={(serviceContainer: ServiceContainer) => {
                                    this.props.updateServiceContainer({
                                        uuid: this.state.serviceContainer?.uuid,
                                        ...serviceContainer
                                    })
                                }}
                            />
                            {this.state.instance !== null && !this.state.instance.isDeleted() ? 
                                <InstanceAuthenticateModal 
                                    open={this.state.isInstanceAuthenticateModalOpen}
                                    instance={this.state.instance}
                                    onClose={this.dialogClosed}
                                />
                            :
                                <></>
                            }
                            {appReducer.isFetchingData.get(ActionTypes.API_CONNECTOR_CONTAINER_GET_CONFIG) ?
                                <Backdrop className={classes.backdrop} open={true}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                :
                                this.state.connectorContainer != null && <ConnectorContainerConfigDialog
                                    open={this.state.isConfigContainerModalOpen}
                                    onModalClosePress={this.dialogClosed}
                                    config={connectorContainerReducer?.configData}
                                    connectorContainer={this.state.connectorContainer}
                                    onModalSubmit={(config: object) => {
                                        const controller = new ConnectorContainerController();
                                        const unfilteredConfig = controller.prepareConfig(connectorContainerReducer.configData, config)
                                        const configArgs = {
                                            uuid: this.state.connectorContainer?.uuid,
                                            config: controller.filterUnchanged(unfilteredConfig, connectorContainerReducer.configData)
                                        } as ConnectorContainerSetConfigParams
                                        this.props.setConnectorConfig(configArgs)
                                        const files = controller.prepareFiles(connectorContainerReducer.configData, config)
                                        files.forEach((file: FileUploadModel) => {
                                            const fileArgs = {
                                                uuid: this.state.connectorContainer?.uuid,
                                                fileUploadModel: file
                                            } as SetConfigFilesParams
                                            this.props.setConnectorConfigFiles(fileArgs)
                                        });
                                    }}
                                />
                            }
                        </Grid>
                    </Grid>
                </Container>
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: any) => ({
    instanceReducer: state.instances,
    connectorContainerReducer: state.connectorContainers,
    appReducer: state.app,
    serviceContainerReducer: state.serviceContainers
});
const mapDispatchToProps = (dispatch) => {
    return {
        createInstance: (params: Instance) => dispatch(InstanceActions.CreateInstanceCreate(params)),
        retrieveInstance: (params: string) => dispatch(InstanceActions.CreateInstanceRetrieve(params)),
        updateInstance: (params: InstanceUpdateRequest) => dispatch(InstanceActions.CreateInstanceUpdate(params)),
        deleteInstance: (params: InstanceDeleteRequest) => dispatch(InstanceActions.CreateInstanceDelete(params)),
        triggerTaskInstance: (params: InstanceTriggerJobParams) => dispatch(InstanceActions.CreateInstanceTriggerJob(params)),
        createConnector: (params: ConnectorContainer) => dispatch(ConnectorContainerActions.CreateConnectorContainerCreate(params)),
        createServiceContainer: (params: ServiceContainer) => dispatch(ServiceContainerActions.CreateServiceContainerCreate(params)),
        deleteConnector: (params: string) => dispatch(ConnectorContainerActions.CreateConnectorContainerDelete(params)),
        listConnectors: (params: InstanceConnectorListRequest) => dispatch(InstanceActions.CreateInstanceConnectorList(params)),
        listServiceContainers: (params: InstanceServiceListRequest) => dispatch(InstanceActions.CreateInstanceServiceContainerList(params.serviceContainerRetrieveListModel, params.uuid)),
        getConnectorConfig: (params: string) => dispatch(ConnectorContainerActions.CreateConnectorContainerGetConfig(params)),
        setConnectorConfig: (params: ConnectorContainerSetConfigParams) => dispatch(ConnectorContainerActions.CreateConnectorContainerSetConfig(params)),
        setConnectorConfigFiles: (params: SetConfigFilesParams) => dispatch(ConnectorContainerActions.CreateConnectorContainerSetConfigFiles(params)),
        clearConnectors: () => dispatch(InstanceActions.CreateClearConnectors()),
        updateConnectorContainer: (params: ConnectorContainerUpdateParams) => dispatch(ConnectorContainerActions.CreateConnectorContainerUpdate(params)),
        updateServiceContainer: (params: ServiceContainerUpdateParams) => dispatch(ServiceContainerActions.CreateServiceContainerUpdate(params)),
        instanceSetImageVersions: (params: InstanceSetImageVersionRequest) => dispatch(InstanceActions.CreateInstanceSetImageVersion(params)),
        retrieveConnectorContainer: (params: string) => dispatch(ConnectorContainerActions.CreateConnectorContainerRetrieve(params)),
        retrieveServiceContainer: (params: string) => dispatch(ServiceContainerActions.CreateServiceContainerRetrieve(params)),
        instanceAdminPasswordUpdate: (params: InstanceAdminUpdateRequest) => dispatch(InstanceActions.CreateInstanceUpdateAdminPassword(params)),
        instanceCreateToken: (params: InstanceAuthenticateCreateTokenRequest) => dispatch(InstanceActions.CreateInstanceAuthenticateCreateToken(params)),
        triggerTasksCC: (params: ConnectorContainerTriggerJobParams) => dispatch(ConnectorContainerActions.CreateConnectorContainerTriggerJob(params)),
        clearInstances: (params: boolean) => dispatch(InstanceActions.CreateClearInstanceList(params))
    }
}
export default tableConnect(
    mapStateToProps,
    mapDispatchToProps,
    withTranslation()(
        withRouter(
            withSnackbar(
                withStyles(InstanceView, InstanceViewStyles)
            )
        )
    )
);