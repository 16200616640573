import { takeLatest, put } from 'redux-saga/effects';
import ActionTypes from "../config/ActionTypes";
import { ApiHandler } from './APIHandler';
import { AppActions } from 'reducers/types/App';
import MetabaseActions, { MetabaseCreate, MetabaseDelete, MetabaseHistoryList, MetabaseRetrieve, MetabaseRetrieveDashboard } from 'reducers/types/Metabase';
import { MetabaseListRequest, MetabaseApi, MetabaseCreateRequest, MetabaseHistoryListRequest, MetabaseDeleteRequest, MetabaseRetrieveDashboardsRequest } from 'api/apis/MetabaseApi';

export function* MetabaseSagas() {
    yield takeLatest(ActionTypes.API_METABASE_RETRIEVE_DASHBOARD, MetabaseSaga.metabaseRetrieveDashboardWorkerSaga);
    yield takeLatest(ActionTypes.API_METABASE_RETRIEVE, MetabaseSaga.metabaseRetrieveWorkerSaga);
    yield takeLatest(ActionTypes.API_METABASE_CREATE, MetabaseSaga.metabaseCreateWorkerSaga);
    yield takeLatest(ActionTypes.API_METABASE_DELETE, MetabaseSaga.metabaseDeleteWorkerSaga);
    yield takeLatest(ActionTypes.API_METABASE_HISTORY_LIST, MetabaseSaga.metabaseHistoryListWorkerSaga);
}

export class MetabaseSaga {

    static *metabaseRetrieveWorkerSaga(action: MetabaseRetrieve) {
        try {
            const metabasesArgs = {
                metabaseRetrieveListModel: {
                    filters: {
                        uuid: action.params
                    }
                }
            } as MetabaseListRequest
            const data = yield ApiHandler(new MetabaseApi().metabaseList, metabasesArgs)
            yield put(MetabaseActions.CreateMetabaseRetrieveSuccess(data.items[0]));
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_METABASE_RETRIEVE_FAILURE, error as Error));
        }
    }

    static *metabaseRetrieveDashboardWorkerSaga(action: MetabaseRetrieveDashboard) {
        try {
            const metabasesArgs = {
                metabaseRetrieveDashboardsListModel: {
                    filters: {
                        uuid: action.params
                    }
                }
            } as MetabaseRetrieveDashboardsRequest
            const data = yield ApiHandler(new MetabaseApi().metabaseRetrieveDashboards, metabasesArgs)

            // dispatch a success action to the store with the activities
            yield put(MetabaseActions.CreateMetabaseRetrieveDashboardSuccess(data))
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_METABASE_RETRIEVE_DASHBOARD_FAILURE, error as Error))
        }
    }

    static *metabaseCreateWorkerSaga(action: MetabaseCreate) {
        try {

            const metabasesArgs = {
                metabaseCreateModel: {
                    uuid: action.params
                }
            } as MetabaseCreateRequest
            const data = yield ApiHandler(new MetabaseApi().metabaseCreate, metabasesArgs)

            // dispatch a success action to the store with the activities
            yield put(MetabaseActions.CreateMetabaseCreateSuccess(data))
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_METABASE_CREATE_FAILURE, error as Error))
        }
    }

    static *metabaseDeleteWorkerSaga(action: MetabaseDelete) {
        try {
            const metabasesArgs = {
                id: action.params
            } as MetabaseDeleteRequest
            yield ApiHandler(new MetabaseApi().metabaseDelete, metabasesArgs)

            // dispatch a success action to the store with the activities
            yield put(MetabaseActions.CreateMetabaseDeleteSuccess())
        } catch (error) {
            // dispatch a failure action to the store with the error
            yield put(AppActions.CreateError(ActionTypes.API_METABASE_CREATE_FAILURE, error as Error))
        }
    }

    static *metabaseHistoryListWorkerSaga(action: MetabaseHistoryList) {
        try {
            const metabaseArgs = {
                id: action.params?.id,
                historyModelRetrieveListModel: action.params?.historyModelRetrieveListModel
            } as MetabaseHistoryListRequest

            const data = yield ApiHandler(new MetabaseApi().metabaseHistoryList, metabaseArgs);
            yield put(MetabaseActions.CreateMetabaseHistoryListSuccess(data));
        } catch (error) {
            yield put(AppActions.CreateError(ActionTypes.API_METABASE_HISTORY_LIST_FAILURE, error as Error))
        }
    }

}

